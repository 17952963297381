<template>
<el-dialog :visible.sync="visible" :width="$store.getters.isMobile ? '100%' : '720px'" title="邀请记录">
  <div class="invite-code__history">
<!--    {{ paymentColumns }}-->
    <el-table :data="list" border max-height="400" empty-text=" ">
      <el-table-column prop="invitee" width="150" label="受邀人"></el-table-column>
      <el-table-column prop="create_time" width="150" label="登录时间"></el-table-column>
      <el-table-column v-for="(item, index) in paymentColumns" :key="index" prop="date" width="150" :column-key="String(item.level)" :label="item.numberPrice + ' ' + item.currency">
        <template #default="scope">
          <i v-if="scope.row.gift_issue_status.find(gift => gift.gift_level === Number(scope.column.columnKey))?.status" class="el-icon-check text-success-6 font-bold" />
          <i v-else class="el-icon-close text-danger font-bold" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</el-dialog>
</template>

<script>
import { Table, Dialog, TableColumn } from 'element-ui'
import {formatZoneTime} from "~/utils";
export default {
  components: {
    [Table.name]: Table,
    [Dialog.name]: Dialog,
    [TableColumn.name]: TableColumn
  },
  props: {
    paymentColumns: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      visible: false,
      list: [],
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        this.getList()
      })
    },
    async getList() {
      const [err, res] = await this.$utils.to(this.$api.activity.inviterHistory())
      if (!err) {
        this.list = (res?.data || []).map(item => {
          const newItem = {...item}
          newItem.create_time = formatZoneTime(newItem.create_time * 1000).text
          return newItem
        })
      }
    }
  }
}
</script>

<style lang="less">
.invite-code__history {
  .el-table {
    * {
      &::-webkit-scrollbar-thumb {
        background-color: #999;
      }
    }
  }
}
</style>
