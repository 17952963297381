// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".invite-code__bind{position:relative}.invite-code__bind .btn{background-color:#000;border-radius:0 var(--invite-code-button-radius) var(--invite-code-button-radius) 0;cursor:pointer;font-size:24px;font-weight:700;height:60px;line-height:60px;min-width:168px;overflow:hidden;padding:0 12px;text-align:center}.invite-code__bind .btn span{color:var(--invite-code-button-color)}.invite-code__bind .confirm-btn{cursor:pointer;font-size:24px;font-weight:700;line-height:64px;margin:0 auto;padding:0 20px;text-align:center;width:-moz-fit-content;width:fit-content}.invite-code__bind .confirm-btn span{color:var(--invite-code-button-color)}.invite-code__bind .input-box{display:flex;margin:20px auto}.invite-code__bind .input-box .inner-input{border:1px solid #dedede;border-radius:var(--invite-code-button-radius) 0 0 var(--invite-code-button-radius);font-size:20px;height:60px;outline:none;padding:0 15px;width:320px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
