<template>
  <el-dialog class="invite-code-guide" :visible.sync="visible" :width="$store.getters.isMobile ? '100%' : '420px'" title="邀请教程">
    <div class="text-center text-16">
      <div class="step">
        <div class="title">第1步</div>
        <div>点击复制按钮复制你的专属邀请码。</div>
      </div>
      <div class="step">
        <div class="title">第2步</div>
        <div>将邀请码发送给好友。</div>
      </div>
      <div class="step">
        <div class="title">第3步</div>
        <div>引到好友登录KOP商城并绑定UID成功。</div>
      </div>
      <div class="step">
        <div class="title">第4步</div>
        <div>在UID登录成功的24小时内，填写邀请码并绑定成功。</div>
      </div>
      <div class="step">
        <div class="title">第5步</div>
        <div>按要求持续完成任务，双方即可获得奖励。</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog } from 'element-ui'

export default {
  components: {
    [Dialog.name]: Dialog
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    init() {
      this.visible = true
    }
  }
}

</script>

<style lang="less">
.invite-code-guide {
  .step {
    margin-bottom: 20px;
    .title {
      color: black;
    }
  }
}
</style>
