<template>
  <el-popover title="文字颜色" popper-class="font-color-popper" :visible-arrow="false">
    <div class="content">
      <div class="flex items-center">
        <el-color-picker v-model="color" @change="onChange" />
        <eye-dropper class="!text-[#666] flex-shrink-0 ml-2" @change="onEyeChange" />
      </div>
      <div class="color-list mt-2">
        <div v-for="(item, index) in colorList" :key="index" class="item" :style="{ backgroundColor: item }" @click="handleClick(item)" />
      </div>
    </div>
    <i slot="reference" class="iconfont icon-fuwenbenbianjiqi_zitiyanse" :style="getStyle" />
  </el-popover>
</template>

<script>
import { Popover, ColorPicker } from 'element-ui';
import EyeDropper from "~/components/common/EyeDropper.vue";
export default {
  name: "FontColor",
  components: {
    EyeDropper,
    [Popover.name]: Popover,
    [ColorPicker.name]: ColorPicker
  },
  data() {
    return {
      color: '',
      colorList: [
        '#000000',
        '#434343',
        '#666666',
        '#999999',
        '#b7b7b7',
        '#cccccc',
        '#d9d9d9',
        '#efefef',
        '#f3f3f3',
        '#ffffff',
        '#980000',
        '#ff0000',
        '#f90',
        '#ff0',
        '#0f0',
        '#0ff',
        '#4a86e8',
        '#00f',
        '#90f',
        '#f0f',
        '#e6b8af',
        '#f4cccc',
        '#fce5cd',
        '#fff2cc',
        '#d9ead3',
        '#d0e0e3',
        '#c9daf8',
        '#cfe2f3',
        '#d9d2e9',
        '#ead1dc',
        '#dd7e6b',
        '#ea9999',
        '#f9cb9c',
        '#ffe599',
        '#b6d7a8',
        '#a2c4c9',
        '#a4c2f4',
        '#9fc5e8',
        '#b4a7d6',
        '#D3A6BC',
        '#cc4125',
        '#e06666',
        '#f6b26b',
        '#ffd966',
        '#93c47d',
        '#76a5af',
        '#6d9eeb',
        '#6fa8dc',
        '#8e7cc3',
        '#c27ba0',
        '#a61c00',
        '#c00',
        '#e69138',
        '#f1c232',
        '#6aa84f',
        '#45818e',
        '#3c78d8',
        '#3d85c6',
        '#674ea7',
        '#a64d79',
        '#85200c',
        '#900',
        '#b45f06',
        '#bf9000',
        '#38761d',
        '#134f5c',
        '#15c',
        '#0b5394',
        '#351c75',
        '#741b47',
        '#5b0f00',
        '#600',
        '#783f04',
        '#7f6000',
        '#274e13',
        '#0c343d',
        '#1c4587',
        '#073763',
        '#20124d',
        '#4c1130'
      ]
    }
  },
  computed: {
    getStyle() {
      return {
        color: this.color
      }
    }
  },
  methods: {
    onChange(color) {
      this.$emit('change', color)
    },
    init(color) {
      this.color = color
    },
    onEyeChange(color) {
      this.onChange(color)
      this.color = color
    },
    handleClick(color) {
      this.onChange(color)
      this.color = color
    }
  }
}
</script>

<style lang="less">
.font-color-popper {
  .el-popover__title {
    font-size: 14px;
  }
  .content {
    .el-color-picker {
      height: 16px;
      width: 100%;
      &__trigger {
        width: 100%;
        height: 100%;
        padding: 0;
        border: none;
      }
      &__color {
        border: none;
      }
      &__icon {
        color: black;
        font-weight: bold;
      }
    }
    .color-list {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      gap: 5px;
    }
    .item {
      width: 16px;
      height: 16px;
      border: 1px solid transparent;
      cursor: pointer;
      &:hover {
        border-color: red;
      }
    }
  }
}
</style>

<style scoped lang="less">
/deep/ .el-popover__reference-wrapper {
  display: flex;
}
</style>
