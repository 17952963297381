<template>
  <div class="store-list-edt">
    <menu-item label="背景设置">
      <menu-background-image :target="model.background" />
      <menu-fill class="mt-2" :target="model.background" />
    </menu-item>
    <menu-item label="布局">
      <menu-switch v-model="model.titleVisible" label="标题" />
      <menu-switch v-model="model.subtitleVisible" label="描述" />
    </menu-item>
    <menu-item label="商品ICON">
      <menu-switch v-model="model.picVisible" label="开启" />
    </menu-item>
    <menu-item label="按钮背景">
      <menu-background-image :target="model.btnBackground" target-key="btnBackground" />
      <menu-fill :target="model.btnBackground" class="mt-2" target-key="btnBackground" />
    </menu-item>
    <menu-item label="商品名称颜色">
      <menu-fill :target="model.nameBackground" target-key="nameBackground" />
    </menu-item>
    <menu-item label="商品描述颜色">
      <menu-fill :target="model.descBackground" target-key="descBackground" />
    </menu-item>
    <menu-item label="商品价格颜色">
      <menu-fill :target="model.priceBackground" target-key="priceBackground" />
    </menu-item>
    <menu-item label="商品价格按钮">
      <menu-fill :target="model.priceBtnBackground" target-key="priceBtnBackground" />
    </menu-item>
    <menu-item label="商品背景">
      <menu-fill :target="model.goodsBackground" target-key="goodsBackground" />
    </menu-item>
    <menu-item label="商品分类">
      <div v-for="(item, index) in model.classList" :key="index" class="item w-full flex items-center justify-between bg-fillColor-2 p-2 rounded-sm mb-2">
        <im-select v-model="item.id" size="small" :options="$store.state.goods.groupList" />
        <i class="el-icon-delete ml-4 mr-2 cursor-pointer hover:text-danger" @click="handleRemove(index)" />
      </div>
    </menu-item>
    <menu-button class="mt-4" type="ghost" icon="add" @click.native="handleAdd">添加商品分类</menu-button>
    <remove-btn class="mt-4" />
  </div>
</template>

<script>
import RemoveBtn from '~/components/common/RemoveBtn.vue'
import MenuItem from '~/components/menu/menuItem.vue'
import MenuBackgroundImage from '~/components/menu/menuBackgroundImage.vue'
import MenuFill from '~/components/menu/menuFill.vue'
import MenuSwitch from '~/components/menu/menuSwitch.vue'
import MenuButton from '~/components/menu/menuButton.vue'
import ImSelect from '~/components/common/ImSelect.vue'

export default {
  name: 'StoreListEdit',
  components: {ImSelect, MenuButton, MenuSwitch, MenuFill, MenuBackgroundImage, MenuItem, RemoveBtn},
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      classOptions: []
    }
  },
  created() {
    //
  },
  methods: {
    handleAdd() {
      this.model.classList.push({
        id: ''
      })
    },
    handleRemove(index) {
      this.model.classList.splice(index, 1)
    }
  },
}
</script>

<style scoped lang="less">

</style>
