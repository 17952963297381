<template>
<div class="invite-code__bind">
  <div class="text-center font-bold title">我是受邀者</div>
  <div class="text-center text-[#999] mt-2 subtitle">邀请码只可填写一次，不可更改</div>
  <div class="flex justify-center">
    <div class="input-box">
      <input v-model="code" class="inner-input" :disabled="isDisabled" placeholder="输入邀请码" />
      <bg-style v-imloading="bindLoading" :bg="model.buttonBackground" class="btn" @click.native="handleBind">
        <span class="relative">绑定邀请码</span>
      </bg-style>
    </div>
  </div>
  <invite-rule class="mt-5" :model="model" :editing="editing" />
  <bg-style v-if="!editing" v-imloading="confirmLoading" :bg="model.buttonBackground" :radius="model.buttonRadius" class="confirm-btn mt-8 mx-auto w-fit" @click.native="handleCreate">
    <span class="relative">成为推广大使</span>
  </bg-style>
</div>
</template>

<script>
import {Message} from "element-ui";
import {mapState} from "vuex";
import InviteRule from './rule.vue'
import {checkUser} from "~/utils";
export default {
  components: {
    InviteRule
  },
  props: {
    confirmLoading: {
      type: Boolean,
      default: false
    },
    editing: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      code: '',
      bindLoading: false
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.siteUserInfo,
      projectId: (state) => state.project.projectId,
      siteId: (state) => state.project.info.id
    }),
    isDisabled() {
      return !this.userInfo?.uid
    }
  },
  methods: {
    async handleBind() {
      if (!this.code) return
      const params = {
        invite_code: this.code
      }
      await checkUser(this)
      this.bindLoading = true
      const [err] = await this.$utils.to(this.$api.activity.inviteCodeBind(params))
      this.bindLoading = false
      if (!err) {
        Message.success('Success')
        this.$emit('bind-success')
      } else {
        Message.error(err)
      }
    },
    handleCreate() {
      this.$emit('create')
    }
  }
}
</script>

<style lang="less">
.invite-code__bind {
  position: relative;
  .btn {
    height: 60px;
    line-height: 60px;
    min-width: 168px;
    padding: 0 12px;
    background-color: black;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    cursor: pointer;
    border-radius: 0 var(--invite-code-button-radius) var(--invite-code-button-radius) 0;
    overflow: hidden;
    span {
      color: var(--invite-code-button-color);
    }
  }
  .confirm-btn {
    font-size: 24px;
    margin: 0 auto;
    line-height: 64px;
    width: fit-content;
    text-align: center;
    font-weight: bold;
    padding: 0 20px;
    cursor: pointer;
    span {
      color: var(--invite-code-button-color);
    }
  }
  .input-box {
    display: flex;
    margin: 20px auto;
    .inner-input {
      width: 320px;
      height: 60px;
      padding: 0 15px;
      border: 1px solid #dedede;
      outline: none;
      font-size: 20px;
      border-radius: var(--invite-code-button-radius) 0 0 var(--invite-code-button-radius);
    }
  }
}
</style>
